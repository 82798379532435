import featureFlagMixin from '@motionelements/core/src/components/mixins/feature-flag.mixin.js';
import routeMixin from '@motionelements/core/src/components/mixins/route.mixin.js';

export default {
  mixins: [featureFlagMixin, routeMixin],
  methods: {
    goToLogin(params) {
      if (this.isFeatureEnabled('auth-modals')) {
        this.$bvModal.show('login-modal');
        if (params) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              ...Object.fromEntries(params.map(param => param.split('='))),
            },
          });
        }
      } else {
        const paramsString = params ? `?${params.join('&')}` : '';
        window.location.href = this.urlWithRedirect(`/login${paramsString}`);
      }
    },
    goToSignUp(params) {
      if (this.isFeatureEnabled('auth-modals')) {
        this.$bvModal.show('sign-up-modal');
        if (params) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              ...Object.fromEntries(params.map(param => param.split('='))),
            },
          });
        }
      } else {
        const paramsString = params ? `?${params.join('&')}` : '';
        window.location.href = this.urlWithRedirect(`/signup${paramsString}`);
      }
    },
  },
};
