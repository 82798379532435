//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'NavLink',
  props: {
    to: {
      type: String,
      required: true,
    },
    repo: {
      type: String,
      // required: true,
      default: 'frontend-channels2022',
    },
  },
  computed: {
    isCurrentRepo() {
      return process.env.VUE_APP_PROJECT_NAME && process.env.VUE_APP_PROJECT_NAME === this.repo;
    },
    isNuxtRepo() {
      return ['frontend-channels2022'].includes(this.repo);
    },
  },
};
