//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClickOutside from 'vue-click-outside';
import { mapState, mapGetters } from 'vuex';

import {
  faSignIn,
  faBars,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faUser,
  faShoppingCart,
} from '@motionelements/assets/src/js/me-font/icons-fa6';

import { faMeEarth } from '@motionelements/assets/src/js/me-font/icons-channels2022';

import authMixin from '@motionelements/core/src/components/mixins/auth.mixin.js';

import NavLogo from './NavLogo.vue';

export default {
  name: 'TheNavBarMobile',
  props: {
    isTransparent: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [authMixin],
  directives: {
    ClickOutside,
  },
  components: {
    NuxtClientOnly: () => import('./NuxtClientOnly.vue'),
    LanguagesList: () => import('@motionelements/core/src/components/modules/common/MenuLanguagesList.vue'),
    NavLogo,
    ExploreMenu: () => import('./ExploreMenu.vue'),
    UserNavMobile: () => import('./UserNavMobile.vue'),
    CustomSidebar: () => import('./CustomSidebar.vue'),
    CartNav: () => import('./CartNavList.vue'),
  },
  data() {
    return {
      icons: {
        faBars,
        faSignIn,
        faMeEarth,
        faShoppingCart,
        faUser,
      },
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.user.isLoggedIn,
    }),
    ...mapGetters({
      getTotalItemsCount: 'cart/getTotalItemsCount',
    }),
    isTransparentBg() {
      return this.isTransparent && !this.isExploreMenuVisible;
    },
  },
};
