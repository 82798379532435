import featureFlagMixin from '@motionelements/core/src/components/mixins/feature-flag.mixin.js';

export default {
  mixins: [featureFlagMixin],
  methods: {
    urlWithRedirect(path) {
      // build url with intended_url
      const currentPath = this.$route.fullPath;
      // home page or part of currentPath
      if (currentPath === '/' || currentPath.indexOf(path) === 0 || path.includes('intended_url')) {
        return this.$link(path).url();
      }

      return this.$link(path).param('intended_url', currentPath).url();
    },
  },
};
